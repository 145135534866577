module.exports = [{
      plugin: require('/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-tinacms-git","id":"ca65af08-4c10-5e6f-8b14-831709d38ab4","name":"gatsby-tinacms-git","version":"0.2.7","pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-tinacms-json","id":"acc053f9-4f17-5fb2-9948-4bd93063a73c","name":"gatsby-tinacms-json","version":"0.6.0","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]}],"sidebar":{"hidden":true}},
    },{
      plugin: require('/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
