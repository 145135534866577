// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-about-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/de/about.js" /* webpackChunkName: "component---src-pages-de-about-js" */),
  "component---src-pages-de-contact-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/de/contact.js" /* webpackChunkName: "component---src-pages-de-contact-js" */),
  "component---src-pages-de-datapolicy-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/de/datapolicy.js" /* webpackChunkName: "component---src-pages-de-datapolicy-js" */),
  "component---src-pages-de-feedback-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/de/feedback.js" /* webpackChunkName: "component---src-pages-de-feedback-js" */),
  "component---src-pages-de-impressum-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/de/impressum.js" /* webpackChunkName: "component---src-pages-de-impressum-js" */),
  "component---src-pages-de-index-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-services-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/de/services.js" /* webpackChunkName: "component---src-pages-de-services-js" */),
  "component---src-pages-de-work-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/de/work.js" /* webpackChunkName: "component---src-pages-de-work-js" */),
  "component---src-pages-en-about-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-datapolicy-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/en/datapolicy.js" /* webpackChunkName: "component---src-pages-en-datapolicy-js" */),
  "component---src-pages-en-feedback-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/en/feedback.js" /* webpackChunkName: "component---src-pages-en-feedback-js" */),
  "component---src-pages-en-impressum-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/en/impressum.js" /* webpackChunkName: "component---src-pages-en-impressum-js" */),
  "component---src-pages-en-index-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-services-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-work-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/en/work.js" /* webpackChunkName: "component---src-pages-en-work-js" */),
  "component---src-pages-index-js": () => import("/Users/lennartgastler/Documents/web/GNWeb/roesler/roessler-gatsby-tina/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

